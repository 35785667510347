export default {
  data() {
    return {
      scrollTop: 0,
      xtys: {
        offsetTop: "",
        xtys1: ""
      }
    }
  },
  methods: {
    // 
    bannerScroll(){

    },
    // 系统优势
    xtysScroll() {
      let that = this;
      that.xtys = {
        // 功能距离顶部距离
        offsetTop: that.$refs.xtys.offsetTop,
        // ref高度
        xtys1: that.$refs.xtys1.offsetHeight
      };
    },
    xtysStyle() {
      let _style = "";
      let _a = this.xtys.offsetTop + this.xtys.xtys1 - 400;
      let _opacity = 0;
      if (this.scrollTop > _a) {
        _opacity = ((this.scrollTop - _a) / 400);
      }
      console.log(_opacity);
      _style += "opacity: " + _opacity + ";";
      return _style;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  }
}
