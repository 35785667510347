<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "",
  data() {
    return {
      config:{
          title:"机器人与七夕的微妙联系",
          source:"来源：浩新科技 2019-09-25",
      }
    };
  },
  methods: {
    
  },
  mounted() {

  },
  activated() {
  }
};
</script>



